


























import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { AxiosResponse } from 'axios';
import { IDataTable } from '@/model/main/IDataTable';
import { IReport } from '@/model/main/IReport';
import { IHeaderTable } from '@/model/main/IHeaderTable';


@Component({
	name: 'TableReports',
	components: {
		DataTable,
	},
})
export default class TableReports extends MixinMain {
	public title = this.$t("MainReports.titleTable");
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
//	public footer: Array<IFootTable<IDataTable>> = [];
	public dateSelected = '';
	public id = 0;
	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.getData();
		}
	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`vue-xentric/getReporte?${this.getFilterUrl}`);

		Util.waitForPromises([request_1])
			.then((responses) => {
				const data: Array<IReport> = responses[0]
					.data['reporte'] as Array<IReport>;
				
				const dataTable: Array<IDataTable> = [];
				console.log(this.getFilter);
				for (let item of data) {
					console.log(item);

					const row: IDataTable = {};
					row['date'] = item.Fecha;
					row['aud'] = item.Audios;
					row['met'] = item.Metadata;
					row['tim'] = item.Tiempo;
					row['tun'] = item.Tunning;
					//console.log(JSON.stringify(item.tunning))
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						switch (key) {
							case 'date':
								text = this.$t("MainReports.dateTitle") as string;
								break;
							case 'aud':
								text = this.$t("MainReports.audiosTitle") as string;
								break;
							case 'met':
								text = this.$t("MainReports.metadataTitle") as string;
								break;
							case 'tim':
								text = this.$t("MainReports.timeTitle") as string;
								break;
							case 'tun':
								text = this.$t("MainReports.tunningTitle") as string;
								break;
						}
						return {
							text,
							value: key,
						};
					}
				) as Array<IHeaderTable<IDataTable>>;
				this.rows = dataTable;
				this.header = header;
				
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	public listenClickRow(payload: IDataTable) {
		//console.log(payload);
		this.$emit('changeEvent', payload.date);
	}

}

	

