

























import { Component, Watch, Prop } from 'vue-property-decorator';
import DataTable from '@/components/util/DataTable.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { AxiosResponse } from 'axios';
import { IDataTable } from '@/model/main/IDataTable';
import { IReportDetails } from '@/model/main/IReportDetails';
import { IHeaderTable } from '@/model/main/IHeaderTable';

@Component({
	name: 'TableReports',
	components: {
		DataTable,
	},
})
export default class TableReports extends MixinMain {
	@Prop({
		type: String,
		required: true,
	})
	readonly dateSelected!: string;
	public subtitle = this.$t("MainReports.subtitle") + `${this.dateSelected}`;
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];
	public reporte_fecha ="";

	@Watch('dateSelected')
	public changedDate(): void {
		this.getData();
	}
	mounted(): void {
		if (this.dateSelected !== undefined) {
			this.getData();
		}
	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`vue-xentric/getReporteDia?Fecha=${this.dateSelected}`);

		Util.waitForPromises([request_1])
			.then((responses) => {
				const data: Array<IReportDetails> = responses[0]
					.data['reporte'] as Array<IReportDetails>;
					this.reporte_fecha = responses[0].data.reporte[0];

				const dataTable: Array<IDataTable> = [];
				for (let item of data) {

					const row: IDataTable = {};
					row['audio'] = item.audio;
					row['duracion'] = item.duracion;
					row['tunning'] = item.tunning;
					row['fecha_tunning'] = item.fecha_tunning;
					row['campania'] = item.campaña;
					row['tipo_llamado'] = item.tipo_llamada;
					//console.log(JSON.stringify(item.tunning))
					dataTable.push(row);
				}
				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						switch (key) {
							case 'audio':
								text = this.$t("MainReports.audiosTitle2") as string;
								break;
							case 'duracion':
								text = this.$t("MainReports.timeTitle2") as string;
								break;
							case 'tunning':
								text = this.$t("MainReports.tunningTitle2") as string;
								break;
							case 'fecha_tunning':
								text = this.$t("MainReports.tunningDateTitle") as string;
								break;
							case 'campania':
								text = this.$t("MainReports.campaign") as string;
								break;
							case 'tipo_llamado':
								text = this.$t("MainReports.titleTipoLlamado") as string;
								break;
						}
						return {
							text,
							value: key,
						};
					}
				) as Array<IHeaderTable<IDataTable>>;
				this.rows = dataTable;
				this.header = header;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	public listenClickRow(payload: IDataTable) {
		console.log(payload);
	}
}

	

