



























import { Component, Watch } from 'vue-property-decorator';
import DataTableTotal from '@/components/util/DataTableTotal.vue';
import MixinMain from '@/mixing/MixinMain.vue';
import { internet } from '@/utils/Internet';
import Util from '@/utils/Util';
import { AxiosResponse } from 'axios';
import { IDataTable } from '@/model/main/IDataTable';
import { IReport } from '@/model/main/IReport';
import { IDateSelected } from '@/model/main/IDateSelected';
import { IHeaderTable } from '@/model/main/IHeaderTable';


@Component({
	name: 'TableReports',
	components: {
		DataTableTotal,
	},
})
export default class TableReportsGeneral extends MixinMain {
	public title = this.$t("MainReports.titleTableGeneral") as string;
	public isLoading = false;
	public header: Array<IHeaderTable<IDataTable>> = [];
	public rows: Array<IDataTable> = [];

	@Watch('getFilter')
	public changedGetFilter(): void {
		this.getData();
	}
	mounted(): void {
		if (this.getFilter !== undefined) {
			this.getData();
		}
	}
	private getData(): void {
		this.isLoading = true;
		const request_1 = internet
			.newRequest()
			.get(`vue-xentric/getReporte?${this.getFilterUrl}`);

		Util.waitForPromises([request_1])
			.then((responses) => {
				const data: Array<IReport> = responses[0]
					.data['reporte'] as Array<IReport>;

				const dataTable: Array<IDataTable> = [];
				const row: IDataTable = {};
				let totalAudios = 0;
				let totalMetadata = 0;
				let totalTiempo = 0;
				let totalTunning = 0;
				let fecha = "-";
				let lenData = data.length;

				if (lenData === 1) {
					fecha = data[0].Fecha;
				} else if (lenData > 1) {
					fecha = `${data[0].Fecha} - ${data[lenData-1].Fecha}`;
				}

				for (let item of data) {
					totalAudios += item.Audios;
					totalMetadata += item.Metadata;
					totalTiempo += item.Tiempo;
					totalTunning += item.Tunning;
					//fecha = item.Total;
				}

				row['date'] = fecha;
				row['aud'] = totalAudios;
				row['met'] = totalMetadata;
				row['tim'] = totalTiempo;
				row['tun'] = totalTunning;
				dataTable.push(row);

				const header: Array<IHeaderTable<
					IDataTable
				>> = Object.keys(dataTable[0]).map(
					(
						key: string
					): IHeaderTable<IDataTable> => {
						let text = key;
						switch (key) {
							case 'date':
								text = this.$t("MainReports.dateTitle") as string;
								break;
							case 'aud':
								text = this.$t("MainReports.audiosTitle") as string;
								break;
							case 'met':
								text = this.$t("MainReports.metadataTitle") as string;
								break;
							case 'tim':
								text = this.$t("MainReports.timeTitle") as string;
								break;
							case 'tun':
								text = this.$t("MainReports.tunningTitle") as string;
								break;
						}
						return {
							text,
							value: key,
						};
					}
				) as Array<IHeaderTable<IDataTable>>;
				this.rows = dataTable;
				this.header = header;
			})
			.catch(console.log)
			.finally(() => {
				this.isLoading = false;
			});
	}
	
}

	

