























import { Component } from 'vue-property-decorator';
import MixinMain from '@/mixing/MixinMain.vue';
import AppContainerBase from '@/components/util/AppContainerBase.vue';
import FilterBarReports from '@/components/reports/FilterBarReports.vue';
import TableReports from '@/components/main/TableReports.vue';
import TableReportsDetail from '@/components/main/TableReportsDetail.vue';
import TableReportsGeneral from '@/components/main/TableReportsGeneral.vue';
import BottonDownloadReporte from '@/components/main/BottonDownloadReporte.vue';
import { IDataTable } from '@/model/main/IDataTable';
//import PieChart from '@/components/main/PieChart.vue';
//import RowChartPie from '@/components/main/RowChartPie.vue';
// Sentry
//import Vue from "vue";
//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";

//Sentry.init({
//	Vue: Vue,
//	dsn: "https://88cdf5f96a5c4f7c9da9901c509d807f@o1164326.ingest.sentry.io/6253333",

//	integrations: [new BrowserTracing],
//	tracesSampleRate: 1.0
	
//})

	@Component({
		name: 'MainReports',
        components: {
			AppContainerBase,
            FilterBarReports,
            TableReports,
            TableReportsDetail,
            TableReportsGeneral,
            BottonDownloadReporte,
    }})
    export default class MainReports extends MixinMain {
		public title = this.$t("MainReports.title");
        public dateSelected = '';
        public noDateSelected = true;
        public dateChanged = 0;

        public changeDate(fecha:string) {
			//console.log("CAMBIANDO A ",fecha);
			this.dateSelected = fecha;
            this.noDateSelected = false;
            this.dateChanged += 1;
		}
    }

