











import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import { IDataTable } from '@/model/main/IDataTable';
import { IHeaderTable } from '@/model/main/IHeaderTable';
import Util from '@/utils/Util';
@Component({
	name: 'DataTableTotal',
})
export default class DataTableTotal extends Vue {
	public noDataText = this.$t("DataTable.noDataText");
	public noDataFound = this.$t("DataTable.noDataFound");

	@Prop({
		type: Array as () => Array<IDataTable>,
		required: true,
	})
	readonly data!: Array<IDataTable>;
	@Prop({
		type: Array as () => Array<string>,
		required: true,
	})
	readonly titles!: Array<string>;
	
	get headers(): Array<IHeaderTable<IDataTable>> {
		let output: Array<IHeaderTable<IDataTable>> = [];
		if (
			this.data.length > 0 &&
			Object.keys(this.data[0]).length ===
				this.titles.length
		) {
			this.titles.forEach(
				(value: string, index: number) => {
					output.push({
						text: value,
						value: Object.keys(this.data[0])[
							index
						],
					});
				}
			);
		}
		return output;
	}
}
